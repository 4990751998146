import {Box, createStyles, Flex, Table} from "@mantine/core";
import {ReactNode, useContext, useEffect, useState} from "react";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {AppDataContext} from "../../../AppData";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import {AdminCompanyResponsePage, AdminGetCompaniesInput} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import {tt} from "../../../core/Localization";
import YesNoBooleanLabel from "../text/YesNoBooleanLabel";
import {addressToSingleLineText} from "../../../service/UserService";
import {industryForId, lastSubscriptionForCompany} from "../../../service/CompanyService";
import {DateTime} from "luxon";
import {getPublicUrls, publicUrlForFile} from "../../../service/StorageService";
import PaginationSection from "../tables/PaginationSection";
import {SetURLSearchParams, useNavigate} from "react-router-dom";
import {PigMoney} from "tabler-icons-react";
import AppIconButton from "../buttons/AppIconButton";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import {companySubscriptionsUri} from "../../screens/companies/CompanySubscriptionsScreen";
import {kTopicCompanies} from "../../../core/constants";
import TableHeadCell from "../tables/TableHeadCell";

const useStyles = createStyles((theme) => ({
    photoUrl: {
        width: 48,
        height: 'auto',
    },
    body: {
        wordBreak: 'break-word',
        '.normal': {
            wordBreak: 'normal',
        },
    },
}));

const kCompaniesPageSize = 10;

export interface ICompaniesTableProps {
    searchParams: URLSearchParams;
    setSearchParams: SetURLSearchParams;
    search?: string;
    companyId?: string;
    industryIds?: string[];
}

/**
 * Table component for displaying Company list.
 */
export default function CompaniesTable(props: ICompaniesTableProps) {
    const { searchParams, setSearchParams, search, companyId, industryIds } = props;

    const navigate = useNavigate();

    const restApiClientContext = useContext(RestApiClientContext);
    const { subscribe } = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const { language } = appDataContext;

    const { classes } = useStyles();
    const { classes: generalClasses } = useGeneralStyles();

    const [publicUrls, setPublicUrls] = useState<Record<string, string>>({});

    const [sortDesc, setSortDesc] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('id');

    const [paramsInitialized, setParamsInitialized] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AdminCompanyResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicCompanies,
            {
                uri: '/admin/company/search',
                params: {
                    search,
                    companyId,
                    industryIds,
                    page,
                    pageSize: kCompaniesPageSize,
                    sortDesc,
                    sortBy,
                } as AdminGetCompaniesInput,
                setLoading,
                onData: (data: AdminCompanyResponsePage) => setData(data),
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [page, search, companyId, industryIds, sortDesc, sortBy]);

    useEffect(() => {
        if (data) {
            setTotal(data.total);
        } else {
            setTotal(0);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            getPublicUrls(data.files)
                .then((urls) => {
                    setPublicUrls(urls);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [data]);

    useEffect(() => {
        if (!paramsInitialized) {
            setPage(searchParams.get('companies-page') ? parseInt(searchParams.get('companies-page') || '0') : 0);
            setParamsInitialized(true);
        }
    }, [searchParams, paramsInitialized]);

    useEffect(() => {
        if (paramsInitialized) {
            setPage(0);
        }
    }, [search, companyId, industryIds]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('companies-search', search || '');
            prev.set('companies-companyId', companyId || '');
            prev.set('companies-page', page.toString());
            prev.set('companies-industryIds', industryIds ? industryIds.join(',') : '');

            return prev;
        });
    }, [search, companyId, industryIds, page]);

    const [rowsJSX, setRowsJSX] = useState<ReactNode[]>([]);
    useEffect(() => {
        if (data) {
            const rows = data.content.map((company) => {
                const lastSubscription = lastSubscriptionForCompany(company.id, data.subscriptions);

                const industries = company.industryIds.map((industryId) => {
                    const industry = industryForId(industryId, data.industries);

                    return industry ? tt(industry.translationKey) : undefined;
                }).join(', ');

                const logoPublicUrl = publicUrlForFile(company.logoFileId, data.files, publicUrls);

                return (
                    <tr key={company.id}>
                        <td className="normal">{company.id}</td>
                        <td>{company.name}</td>
                        <td>
                            {logoPublicUrl ? (
                                <a target="_blank" href={logoPublicUrl}>
                                    <img
                                        className={classes.photoUrl}
                                        src={logoPublicUrl}
                                        alt={tt('companiesTable.header.logoFileId')}
                                    />
                                </a>
                            ) : undefined}
                        </td>
                        <td>{addressToSingleLineText(company.address)}</td>
                        <td>{industries}</td>
                        <td>{company.currency}</td>
                        <td>
                            <YesNoBooleanLabel
                                value={company.hasVat}
                            />
                        </td>
                        <td>{company.timeZone}</td>
                        <td>{lastSubscription ? tt(lastSubscription.translationKey) : null}</td>
                        <td>{lastSubscription ? lastSubscription.maxUsers : null}</td>
                        <td>{lastSubscription ? DateTime.fromMillis(lastSubscription.validTo).toLocaleString(DateTime.DATETIME_MED, { locale: language }) : null}</td>
                        <td align="right">
                            <Flex
                                justify="flex-end"
                            >
                                <AppIconButton
                                    onClick={() => navigate(routeWithCurrentAsParam(companySubscriptionsUri(company.id)))}
                                    tooltip={tt('companiesTable.button.subscriptions')}
                                >
                                    <PigMoney />
                                </AppIconButton>
                            </Flex>
                        </td>
                    </tr>
                );
            });
            setRowsJSX(rows);
        } else {
            setRowsJSX([]);
        }
    }, [data, publicUrls]);

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                    <tr>
                        <TableHeadCell
                            sortKey="id"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('common.table.header.id')}
                        </TableHeadCell>
                        <TableHeadCell
                            sortKey="name"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('companiesTable.header.name')}
                        </TableHeadCell>
                        <th>{tt('companiesTable.header.logoFileId')}</th>
                        <th>{tt('companiesTable.header.address')}</th>
                        <th>{tt('companiesTable.header.industryIds')}</th>
                        <th>{tt('companiesTable.header.currency')}</th>
                        <TableHeadCell
                            sortKey="hasVat"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('companiesTable.header.hasVat')}
                        </TableHeadCell>
                        <th>{tt('companiesTable.header.timeZone')}</th>
                        <th>{tt('companiesTable.header.subscription')}</th>
                        <th>{tt('companiesTable.header.maxUsers')}</th>
                        <th>{tt('companiesTable.header.validTo')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.total || 0)}
                        </Box>
                    </tr>
                </thead>

                <tbody
                    className={classes.body}
                >
                    {rowsJSX}
                </tbody>

                <tfoot>
                    <tr>
                        <TableHeadCell
                            sortKey="id"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('common.table.header.id')}
                        </TableHeadCell>
                        <TableHeadCell
                            sortKey="name"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('companiesTable.header.name')}
                        </TableHeadCell>
                        <th>{tt('companiesTable.header.logoFileId')}</th>
                        <th>{tt('companiesTable.header.address')}</th>
                        <th>{tt('companiesTable.header.industryIds')}</th>
                        <th>{tt('companiesTable.header.currency')}</th>
                        <TableHeadCell
                            sortKey="hasVat"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('companiesTable.header.hasVat')}
                        </TableHeadCell>
                        <th>{tt('companiesTable.header.timeZone')}</th>
                        <th>{tt('companiesTable.header.subscription')}</th>
                        <th>{tt('companiesTable.header.maxUsers')}</th>
                        <th>{tt('companiesTable.header.validTo')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.total || 0)}
                        </Box>
                    </tr>
                </tfoot>
            </Table>

            <PaginationSection
                total={total}
                page={page}
                setPage={setPage}
                pageSize={kCompaniesPageSize}
            />
        </>
    );
}
